<template>
    <div>
        <!-- 信息展示 -->
        <div class="video-msg">
            <div style="font-size: 24px;">{{ details.title }}</div>
            <div style="border: 1px solid rgb(199 198 193);"></div>
            <div style="margin-top: 5px;color: rgb(125 124 117);">{{ details.msg }}</div>
            <div style="margin-top: 5px;">
                <el-tag style="margin-right: 5px;">類型:</el-tag>
                <span v-for="(items, index) in details.tags" :key="index">
                    <el-tag @click="goTagFunc(items)">{{ items }}</el-tag>
                </span>
            </div>
        </div>

        <!-- 视频播放容器和操作台 -->
        <div class="video-wrapper">
            <!-- 视频播放容器 -->
            <div class="video-player" v-loading="loading">
                <div class="video-container">
                    <div class="video-cover" :style="{ backgroundImage: `url(${details.url})` }"></div>
                    <video ref="video" :poster="details.url" controls @fullscreenchange="handleFullscreenChange"
                        @touchstart="handleTouchStart" @touchend="handleTouchEnd"></video>
                    <div class="play-button" @click="playerVideoFunc(details.id)">
                        <img src="../../../assets/images/player.png" alt="Play" />
                    </div>
                </div>
            </div>

            <!-- 操作台 -->
            <div class="controls">
                <div @click="handleClickActor(details.name)" class="controlsItem">
                    <i class="el-icon-user"></i>
                    <div class="actorName">{{ details.name }}</div>
                </div>
                <div @click="goCollFunc" class="controlsItem">
                    <i class="el-icon-thumb"></i>
                    <div class="actorName">{{ isColl ? '取消收藏' : '收藏' }}</div>
                </div>
                <div @click="goZhFunc" class="controlsItem">
                    <i class="el-icon-monitor"></i>
                    <div class="actorName">{{ details.language ? '中文字幕' : '無字幕' }}</div>
                </div>
                <div @click="rewind" class="controlsItem">
                    <i class="el-icon-d-arrow-left"></i>
                    <div>快退</div>
                </div>
                <div @click="pause" class="controlsItem">
                    <i class="el-icon-video-pause"></i>
                    <div>暂停</div>
                </div>
                <div @click="fastForward" class="controlsItem">
                    <i class="el-icon-d-arrow-right"></i>
                    <div>快进</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Hls from 'hls.js';

export default {
    data() {
        return {
            details: {}, // 详情
            loading: true,
            isColl: false, // 是否收藏

            videoSrc: "", // 播放链接
            touchStartTime: 0,
            touchStartX: 0,
        };
    },
    mounted() {
        // 开启自动预加载
        // if (Hls.isSupported()) {
        //   const hls = new Hls();
        //   hls.loadSource(this.videoSrc);
        //   hls.attachMedia(this.$refs.video);
        //   hls.on(Hls.Events.MANIFEST_PARSED, () => {
        //     console.log('Manifest parsed, video playing');
        //   });
        // } else if (this.$refs.video.canPlayType('application/vnd.apple.mpegurl')) {
        //   this.$refs.video.src = this.videoSrc;
        // }
    },
    created() {
        let vid = this.$route.params.id; // 傳到詳情頁面id
        if (vid) {
            window.sessionStorage.setItem("vid", vid);
        } else {
            vid = window.sessionStorage.getItem("vid");
        }
        this.detailApi(vid);
    },
    methods: {
        // 獲取視頻詳情數據
        detailApi(vid) {
            this.$post("api/v1/detail/", { vid }).then(res => {
                this.details = res.body.data;
                this.isColl = res.body.isColl
                this.loading = false
                this.$msgSuccess(res.body.msg)
            }, err => {
                this.$msgError(err.message)
            })
        },
        // 驗證當前視頻是否符合播放
        playerVideoFunc(vid) {
            this.$post("api/v1/detailCheck/", { vid }).then(res => {
                this.videoSrc = res.body.playUrl;
                this.play()
            }, err => {
                this.$msgError(err.message)
            })
        },

        // 播放
        play() {
            if (Hls.isSupported()) {
                const hls = new Hls();
                hls.loadSource(this.videoSrc);
                hls.attachMedia(this.$refs.video);
                hls.on(Hls.Events.MANIFEST_PARSED, () => {
                    this.$refs.video.play();
                    this.$refs.video.controls = true; // 显示控制条
                    this.$refs.video.muted = true; // 默认静音
                    this.hidePlayButton();
                });
            } else if (this.$refs.video.canPlayType('application/vnd.apple.mpegurl')) {
                this.$refs.video.src = this.videoSrc;
                this.$refs.video.play();
                this.$refs.video.controls = true; // 显示控制条
                this.$refs.video.muted = true; // 默认静音
                this.hidePlayButton();
            }
        },
        // 暂停
        pause() {
            this.$refs.video.pause();
        },
        // 快退
        rewind() {
            this.$refs.video.currentTime -= 10; // 快退 10 秒
        },
        // 快进
        fastForward() {
            this.$refs.video.currentTime += 10; // 快进 10 秒
        },
        // 手机端滑动屏幕
        handleFullscreenChange() {
            if (document.fullscreenElement) {
                console.log('Entered fullscreen');
            } else {
                console.log('Exited fullscreen');
            }
        },
        handleTouchStart(event) {
            this.touchStartTime = Date.now();
            this.touchStartX = event.touches[0].clientX;
        },
        handleTouchEnd(event) {
            const touchEndTime = Date.now();
            const touchEndX = event.changedTouches[0].clientX;
            const touchDuration = touchEndTime - this.touchStartTime;
            const touchDistance = touchEndX - this.touchStartX;

            if (touchDuration < 300) { // 快速触摸
                if (touchDistance > 50) { // 向右滑动
                    this.fastForward();
                } else if (touchDistance < -50) { // 向左滑动
                    this.rewind();
                }
            }
        },
        hidePlayButton() {
            this.$refs.video.controls = true; // 显示控制条
            this.$el.querySelector('.play-button').style.display = 'none';
        },
        // 點擊某一個女主
        handleClickActor(name) {
            // 新頁面打開
            let route = this.$router.resolve({ name: "actor", params: { name } });
            window.open(route.href, '_blank');
        },
        // 點擊標簽
        goTagFunc(tagName) {
            window.sessionStorage.setItem("tagName", tagName)
            this.$router.push({ name: "allPage", params: { t: "tag" } });
        },
        // 中文字幕
        goZhFunc() {
            this.$router.push({ name: "allPage", params: { t: "isZh" } });
        },
        // 收藏
        goCollFunc() {
            const vid = this.details.id
            this.$post("api/v1/collect/", { vid }).then(res => {
                this.isColl = res.body.isColl == 1 ? false : true
                const showStr = res.body.isColl == 1 ? "取消收藏" : "收藏成功"
                this.$msgSuccess(showStr)
            }, err => {
                this.$msgError(err.message)
            })
        },
    },
};
</script>

<style scoped>
.video-msg {
    width: 90%;
    margin: 10px auto;
}

.video-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    max-width: 800px;
    margin: 0 auto;
}

.video-player {
    width: 100%;
}

.video-container {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
    /* 16:9 宽高比 */
}

.video-container video,
.video-cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    /* 确保视频内容保持其宽高比 */
}

.play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
}

.play-button img {
    width: 64px;
    height: 64px;
}

/* 底部tarbar栏 */
.controls {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: #f0f0f0;
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    margin-top: 10px;
}

.controlsItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    padding: 5px;
    transition: background-color 0.3s;
    width: 80px;
    /* 设置一个固定宽度 */
    text-align: center;
}

.controlsItem:hover {
    background-color: #e0e0e0;
}

.actorName {
    font-size: 12px;
    margin-top: 5px;
    white-space: nowrap;
    /* 防止文本换行 */
    overflow: hidden;
    /* 隐藏溢出的文本 */
    text-overflow: ellipsis;
    /* 显示省略号 */
    width: 100%;
    /* 确保宽度与父元素一致 */
}
</style>