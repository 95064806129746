import Vue from "vue";
import VueRouter from "vue-router";

// 全局的导航守卫 修复导航到相同路由的错误
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};

// 引入Login组件
import Login from "../components/pagesVue/loginPage/Login.vue";

// tarbar为主组件 其他页面详细在page中使用
import Index from "../components/pagesVue/Index/Index.vue";
import AllDataPage from "../components/pagesVue/AllDataPage/AllDataPage.vue";
import actorPage from "../components/pagesVue/ActorPage/ActorPage.vue";
import detailsPage from "../components/pagesVue/DetailsPage/DetailsPage.vue";
import chatRommPage from "../components/pagesVue/ChatRoomPage/ChatRoomPage.vue";

import AdmPage from "../components/pagesVue/AdminPage/AdmIndexPage.vue";


Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Index,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/actor/:name",
    name: "actor",
    component: actorPage,
  },
  {
    path: "/allPage/:t",
    name: "allPage",
    component: AllDataPage
  },
  {
    path: "/details/:id",
    name: "details",
    component: detailsPage,
  },
  {
    path: "/chat/:id",
    name: "chat",
    component: chatRommPage,
  },
  {
    path: "/adm/",
    name: "adm",
    component: AdmPage,
  },
];

const router = new VueRouter({
  routes,
});

// 挂载路由导航守卫
router.beforeEach((to, from, next) => {
  if (to.path === "/login") {
    // 检查用户是否已经登录
    if (window.sessionStorage.getItem("token")) {
      // 用户已登录，跳转到主页
      next({ path: "/" });
    } else {
      next();
    }
  } else {
    // if (!window.sessionStorage.getItem('token')) {
    //   // 用户未登录，跳转到登录页面
    //   next({ path: '/login' });
    // } else {
    //   next();
    // }
    next();
  }
});

export default router; //  暴露出去
