<template>
    <div class="container userBox">
        <div class="userCss">
            <!-- 用户信息区域 -->
            <div class="userItem">
                <img :src="userInfo.avatar" class="userAvatar" />
                <div class="userDetail">账号: {{ userInfo.nickname }}</div>
            </div>

            <div class="userVip">
                <div class="userVipItemR">
                    <div class="userVipTitle">优惠码</div>
                    <div class="userVipContent">{{ userInfo.coupon }}</div>
                </div>
                <div class="userVipItemL">
                    <div class="userVipTitle">会员时间</div>
                    <div class="userVipContent">开始: {{ userInfo.vipStart ? userInfo.vipStart : '暂未开通' }}</div>
                    <div class="userVipContent">结束: {{ userInfo.vipEnd ? userInfo.vipEnd : '暂未开通' }}</div>
                </div>
            </div>

            <!-- 功能选项区域 -->
            <div style=" width: 100%; padding: 20px 10px;">
                <div class="menu-item" v-for="(item, index) in handleList" :key="index"
                    @click="switchswitchHandle(item.func, item.name)">
                    {{ item.name }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            userInfo: {},
            handleList: []
        }
    },
    mounted() {
        this.initUserFunc()
    },
    methods: {
        initUserFunc() {
            var token = window.sessionStorage.getItem('token')
            if (!token) {
                this.$router.push({ path: "/login" });
            } else {
                this.$post("api/v1/personal/").then(
                    (res) => {
                        if (res.body.data) {
                            this.userInfo = res.body.data;
                            this.handleList = res.body.handleList
                        } else {
                            window.sessionStorage.clear()
                            window.location.reload()
                        }
                    },
                    (err) => {
                        this.$msgError(err.message);
                        this.handleList = [{func: "getOutFunc", name: "退出登录"}]
                    }
                );
            }
        },
        switchswitchHandle(action, name) {
            const actions = {
                goMyCollFunc: () => this.goMyCollFunc(),
                getOutFunc: () => this.getOutFunc(),
                goAdminFunc: () => this.goAdminFunc(),
            };
            if (actions[action]) actions[action]();
        },
        navigateTo(route) {
            this.$router.push(route);
        },
        goMyCollFunc() {
            this.navigateTo({ name: "allPage", params: { t: "isColl" } });
        },
        getOutFunc() {
            this.$alert('是否确认退出', '退出登录', {
                confirmButtonText: '确定',
                callback: action => {
                    if (action == 'confirm') {
                        window.sessionStorage.clear()
                        window.location.reload()
                    }
                }
            });

        },
        goAdminFunc() {
            this.navigateTo({ name: "adm" });
        },

    }
}
</script>

<style>
/* 用户信息展示 */
.userBox {
    display: flex;
    justify-content: center;
    height: 100vh;
}

.userCss {
    width: 100%;
    max-width: 420px;
    background-color: #fff;
    border: 1px solid #f3f3f3;
}

.userItem {
    background-color: #2389ff;
    width: 100%;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.userAvatar {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-bottom: 10px;
}

.userDetail {
    color: #ffffff;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
}

/* 会员 优惠码展示 */
.userVip {
    width: 90%;
    margin: auto;
    background-color: #fff;
    display: flex;
    padding: 10px;
    text-align: center;
    border-radius: 10px 10px 0 0;
    margin-top: -20px;
}

.userVipItemR {
    flex: 1;
    border-right: #cfcccc solid 1px;
}

.userVipItemL {
    flex: 1;
    border-left: #cfcccc solid 1px;
}

.userVipTitle {
    font-weight: 800;
    margin-bottom: 5px;
}

.userVipContent {
    font-size: 14px;
    color: #000000;
}

/* 操作栏 */
.menu-item {
    width: 100%;
    font-size: 18px;
    cursor: pointer;
    padding: 12px;
    background-color: #f2f2f2;
    transition: background-color 0.3s ease;
    position: relative;
    border-bottom: #e6dbdb 1px solid;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.menu-item::after {
    content: ">";
    font-size: 18px;
    color: #666;
}

.menu-item:hover {
    background-color: #e0e0e0;
}

@media (max-width: 480px) {
    .userDetail {
        font-size: 16px;
    }

    .menu-item {
        font-size: 16px;
    }
}
</style>
