<template>
    <div class="container-box">
        <div class="nav">
            <ul>
                <li v-for="(item, index) in navItems" :key="index" :class="{ active: activeIndex === index }"
                    @click="setActive(index)" :style="{ '--clr': item.color }">
                    <span style="color:#fff;font-weight: 800;">
                        <i :class="item.icon"></i>
                        {{ item.text }}
                    </span>
                </li>
                <div class="indicator" :style="indicatorStyle"></div>
            </ul>
        </div>
        <component :is="currentComponent"></component>
    </div>
</template>


<script>
import HomePage from "./HomePage.vue";
import SearchPage from "./SearchPage.vue";
import OohPage from "./OohPage.vue";
import UserPage from "./UserPage.vue";

const componentMap = {
    HomePage,
    SearchPage,
    OohPage,
    UserPage
};

export default {
    data() {
        return {
            activeIndex: 0,
            navItems: []
        };
    },
    mounted() {
        this.$socket.on('onPrompt', data => {
            this.$msgSuccess(data.data)
        });
        this.getTarBarFunc()
    },
    computed: {
        liWidth() {
            return this.navItems.length ? 350 / this.navItems.length : 0;
        },
        indicatorStyle() {
            const offset = this.activeIndex * this.liWidth + (this.liWidth - 70) / 2;
            return {
                transform: `translateX(${offset}px)`
            };
        },
        currentComponent() {
            return this.navItems.length ? this.navItems[this.activeIndex].component : null;
        },
    },
    methods: {
        getTarBarFunc() {
            this.$post("/api/v1/getTarBar/", {}).then(res => {
                this.navItems = res.body.map(item => ({
                    ...item,
                    component: componentMap[item.component]
                }));
            }, err => {
                this.$msgError(err.message);
            })
        },

        setActive(index) {
            this.activeIndex = index;
        }
    }
};
</script>



<style>
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style: none;
}

a {
    text-decoration: none;
}

body {
    min-height: 100vh;
    overflow-y: hidden;
}
</style>
